@import 'Styles/includes';

.Base {
    $root: &;

    :global(.js-state-mobile-menu) & {
        &__Breadcrumbs,
        &__Document,
        &__Footer {
            @include media-max(ML) {
                display: none;
            }
        }
    }
}
