@import 'Styles/includes';

.Popup {
    $root: &;

    display: flex;
    position: fixed;
    right: 8px;
    bottom: 0;
    width: 480px;
    max-width: calc(100vw - 16px);
    transition: transform .5s ease-in-out;
    z-index: $z-Popup;

    @include media(M) {
        right: 32px;
        width: 512px;
    }

    &--Open {
        transform: translateY(0);
    }

    &--Closed {
        transform: translateY(100%);
    }

    &__Container {
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        position: relative;
        padding: 32px 16px 16px;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        background: white;
        box-shadow: 2px 16px 32px 0px rgba(black, .12);

        @include media(M) {
            padding: 40px 32px 32px;
        }

        #{$root}--Confirmation & {
            justify-content: center;
        }
    }

    &__Close {
        position: absolute;
        top: 6px;
        right: 6px;
        width: 24px;
        height: 24px;
        margin: 0;
        border: none;
        border-radius: 0;
        background: transparent;
        box-shadow: none;
        -webkit-appearance: none;
        cursor: pointer;

        @include media(M) {
            top: 12px;
            right: 12px;
        }

        &::before,
        &::after {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            width: 20px;
            height: 2px;
            margin: auto;
            background: $red;
            transform: rotate(45deg);
        }

        &::after {
            transform: rotate(-45deg);
        }
    }

    &__Content {
    }

    &__Title {
        @include textstyle(h5-wysiwyg);
    }

    &__Richtext {
        :global {
            @extend %richtext;
        }

        @include textstyle(body-small);

        margin-top: 8px;

        @include media(M) {
            margin-top: 12px;
        }

        > *:last-child {
            margin-bottom: 0 !important;
        }
    }

    &__Form {
        :global {
            @extend %gravityforms;
        }

        margin-top: 12px;

        @include media(M) {
            margin-top: 16px;
        }

        #{$root}--Confirmation & {
            :global {
                @extend %richtext;
            }

            margin: 0 !important;
        }

        :global {
            .gform_validation_errors {
                display: none;
            }

            .gform_confirmation_message {
                > *:first-child {
                    margin-top: 0;
                }

                > *:last-child {
                    margin-bottom: 0;
                }
            }

            .gform_required_legend {
                display: none;
            }

            .gfield_checkbox,
            .gfield_container_consent,
            .gfield_radio {
                label {
                    font-size: 1.4rem !important;

                    @include media(M) {
                        font-size: 1.6rem !important;
                    }
                }
            }

            .gform_footer .gform_button {
                @extend %form__submit--primary;

                width: auto;
                min-width: 116px;
                margin-top: 6px;
                margin-right: 8px;

                @include media(M) {
                    min-width: 140px;
                    margin-top: 10px;
                }
            }
        }
    }
}
